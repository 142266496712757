import httpRequest from "~/composables/requset";
import type {ApiResponse} from '~/utils/types';

export function banners(): Promise<ApiResponse>{
    return httpRequest(
        'GET',
        '/api/banners/sections/1'
    )
}

export function bannerDetail(id:string): Promise<ApiResponse> {
    return httpRequest(
        'GET',
        '/api/banners/sections/1/'+id
    )
}
